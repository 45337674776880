import Sidebar from "../../components/partials/Sidebar";
import Header from "../../components/partials/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import deleteIcon from "../../images/icons/dangerous.png";
import noImage from "../../images/icons/noImage.jpeg";
import {
  apiEventAreaList,
  apiMemberApproved,
  apiMemberApprovedEdit,
  apiTeamCon,
} from "../../api/master";
import approveIcon from "../../images/icons/tick.png";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import downloadIcon from "../../images/downloads.png";
import Swal from "sweetalert2";
import config from "../../config";
import axios from "axios";
import download from "downloadjs";
import Tippy from "@tippyjs/react";
import { FaDownload } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import { MdModeEditOutline } from "react-icons/md";
import { GrActions } from "react-icons/gr";
import { RiFileExcel2Fill } from "react-icons/ri";
import DownloadExcel from "../../components/DownloadExcel";

const team_dtl = {
  team_dtl_id: "",
  team_id: "",
  name: "",
  mobile: "",
  email: "",
  pic: "",
  id_1: "",
  id_2: "",
  is_active: "",
  rej_reason: "",
  area_id: "",
  area_name: "",
};

const initialState = {
  team_id: "",
  team_name: "",
  team_color: "",
  slots: "",
  manager_name: "",
  mobile: "",
  event_id: "",
  event_name: "",
  team_dtl: [team_dtl],
};

function IteamList(props) {
  const location = useLocation();
  const team = location.state?.team || {};
  console.log("team", team);
  const [refresh, setRefersh] = useState(false);
  const [reqField, setReqField] = useState({});
  const [eventArea, setEventArea] = useState([]);
  const [teamMember, setTeamMember] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
    setShowApprove(() => Array(teamMember.length).fill(false));
    setShowReject(() => Array(teamMember.length).fill(false));
    setSelectArea([]);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const filteredItems = teamMember.filter((item) => item?.is_active == 0);
  console.log("eventArea", eventArea);
  useEffect(() => {
    let payload = {};
    payload = { team_id: team?.team_id };
    apiTeamCon(payload)
      .then((res) => setTeamMember(res.data?.data?.[0]?.members || []))
      .catch((err) => setTeamMember([]));

    payload = { paginate: 0, is_active: 1, event_id: team?.event_id };
    apiEventAreaList(payload)
      .then((res) => setEventArea(res.data?.data?.list || []))
      .catch((err) => setEventArea([]));
  }, [team]);

  useEffect(() => {
    let payload = { team_id: team?.team_id };

    if (refresh) {
      apiTeamCon(payload)
        .then((res) => setTeamMember(res.data?.data?.[0]?.members || []))
        .catch((err) => setTeamMember([]));
    }
    setRefersh(false);
  }, [refresh]);

  const [showApprove, setShowApprove] = useState([]);
  const [showReject, setShowReject] = useState([]);

  const [showApproveAction, setShowApproveAction] = useState(true);
  const [showRejectAction, setShowRejectAction] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setShowApprove(() => Array(teamMember.length).fill(false));
    setShowReject(() => Array(teamMember.length).fill(false));
  }, [teamMember]);

  useEffect(() => {
    setReqField(team?.req_fields ? JSON.parse(team?.req_fields) : {});
  }, []);

  const showHandleApprove = (id = null) => {
    setShowApprove((prev) =>
      prev.map((v, i) => {
        return i == id ? !v : false;
      })
    );

    setShowReject(() => Array(teamMember.length).fill(false));
    setSelectArea(() => "");
  };

  const showHandleReject = (id = null) => {
    setShowReject((prev) =>
      prev.map((v, i) => {
        return i == id ? !v : false;
      })
    );

    setShowApprove(() => Array(teamMember.length).fill(false));
    setSelectArea(() => "");
  };

  const [selectArea, setSelectArea] = useState([]);

  const [actionReason, setActionReason] = useState();
  console.log("selectArea", selectArea);
  console.log("setActionReason", actionReason);

  const [approve, setApprove] = useState("");
  // const handleApprove = (id, team_dtl_id) => {
  //   setApprove(team_dtl_id);
  //   let payload = selectArea
  //     ? {
  //         team_dtl_id: [team_dtl_id],
  //         area_id: selectArea.map((v) => v?.value),
  //         is_active: 1,
  //       }
  //     : {
  //         team_dtl_id: [team_dtl_id],
  //         area_id: null,
  //         is_active: 1,
  //       };
  //   if (!payload.area_id) {
  //     toast.error("Please assign event area");
  //     setApprove("");
  //   } else {
  //     apiMemberApproved(payload)
  //       .then((res) => {
  //         toast.success("Approved Successfully");
  //         setRefersh(true);
  //         setApprove("");
  //       })
  //       .catch((err) => {
  //         toast.error(err?.data?.data?.message || "Something Went Wrong");
  //         console.log("handleApprove", err);
  //         setApprove("");
  //       });
  //   }
  // };
  const handleApprove = (id, team_dtl_id) => {
    setApprove(team_dtl_id);

    let selectedAreaIds = selectArea && selectArea?.map((v) => v?.value);

    if (
      eventArea.length > 0 &&
      (!selectedAreaIds || selectedAreaIds.length == 0)
    ) {
      toast.error("Please assign event area");
      setApprove("");
      return;
    }

    let payload = {
      team_dtl_id: [team_dtl_id],
      area_id: eventArea?.length > 0 ? selectedAreaIds : [],
      is_active: 1,
    };

    apiMemberApproved(payload)
      .then((res) => {
        toast.success("Approved Successfully");
        setRefersh(true);
        setApprove("");
      })
      .catch((err) => {
        toast.error(err?.data?.data?.message || "Something Went Wrong");
        console.log("handleApprove", err);
        setApprove("");
      });
  };
  const handleApproveEdit = (id, team_dtl_id) => {
    setApprove(team_dtl_id);

    let selectedAreaIds = selectArea && selectArea?.map((v) => v?.value);

    if (
      eventArea.length > 0 &&
      (!selectedAreaIds || selectedAreaIds.length == 0)
    ) {
      toast.error("Please assign event area");
      setApprove("");
      return;
    }

    let payload = {
      team_dtl_id: [team_dtl_id],
      area_id: eventArea?.length > 0 ? selectedAreaIds : [],
      is_active: 1,
    };

    apiMemberApprovedEdit(payload)
      .then((res) => {
        toast.success("Edit Successfully");
        setRefersh(true);
        setApprove("");
      })
      .catch((err) => {
        toast.error(err?.data?.data?.message || "Something Went Wrong");
        // console.log("handleApprove", err);
        setApprove("");
      });
  };

  const [reject, setReject] = useState();
  const handleReject = (id, team_dtl_id) => {
    let reasonField = $(`#reason_${id}`);
    setReject(team_dtl_id);

    let payload = {
      team_dtl_id: [team_dtl_id],
      is_active: 2,
      reason: reasonField.val(),
    };
    if (!payload.reason) {
      toast.error("Please provide a reason");
      setReject("");
    } else {
      apiMemberApproved(payload)
        .then((res) => {
          toast.success("Rejected Successfully");
          setRefersh(true);
          setReject("");
        })
        .catch((err) => {
          toast.error(err?.data?.data?.message || "Something Went Wrong");
          console.log("handleReject", err);
          setReject("");
        });
    }
  };

  const handleApproveAction = () => {
    setIsSubmitting(true);
    let selectedAreaIds = selectArea && selectArea?.map((v) => v?.value);
    if (
      eventArea.length > 0 &&
      (!selectedAreaIds || selectedAreaIds.length == 0)
    ) {
      toast.error("Please assign event area");
      setIsSubmitting(false);
      return;
    }

    let payload = {
      team_dtl_id: selectedTeamId.map((v) => v?.team_dtl_id),
      area_id: eventArea?.length > 0 ? selectedAreaIds : [],
      is_active: 1,
    };

    apiMemberApproved(payload)
      .then((res) => {
        toast.success("Approved Successfully");
        setRefersh(true);
        setIsSubmitting(false);
        handleCloseModal();
      })
      .catch((err) => {
        toast.error(err?.data?.data?.message || "Something Went Wrong");
        console.log("handleApprove", err);
        setIsSubmitting(false);
      });
  };

  const handleRejectAction = () => {
    setIsSubmitting(true);

    let payload = {
      team_dtl_id: selectedTeamId.map((v) => v?.team_dtl_id),
      is_active: 2,
      reason: actionReason,
    };
    if (!payload.reason) {
      toast.error("Please provide a reason");
      setIsSubmitting(false);
    } else {
      apiMemberApproved(payload)
        .then((res) => {
          toast.success("Rejected Successfully");
          setRefersh(true);
          handleCloseModal();
          setIsSubmitting(false);
          setActionReason("");
        })
        .catch((err) => {
          toast.error(err?.data?.data?.message || "Something Went Wrong");
          setIsSubmitting(false);
        });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  function handleImageClick(url) {
    setModalImage(url);
    setIsModalOpen(() => true);
  }

  function handleModalClose() {
    setIsModalOpen(() => false);
  }
  const [id, setId] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState([]);
  console.log("setSelectedTeamId", selectedTeamId);

  const handleCheckboxChange = (item) => {
    if (selectedTeamId.includes(item)) {
      setSelectedTeamId(
        selectedTeamId.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedTeamId([...selectedTeamId, item]);
    }
  };
  const handleSelectAll = () => {
    if (selectedTeamId.length === filteredItems.length) {
      setSelectedTeamId([]);
    } else {
      setSelectedTeamId(filteredItems);
    }
  };

  const generatePdf = async (v) => {
    setId(v.team_dtl_id);
    await $.post(
      `${config.apiUrl}/guest/teamCardDownload`,
      JSON.stringify({
        team_dtl_id: v.team_dtl_id,
      })
    )
      .done(async (res) => {
        if (res) {
          const pdfUrl = res?.data?.card_url;
          const downloadResponse = await fetch(pdfUrl);
          const blob = await downloadResponse.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${v.name}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          setId(null);
          Swal.fire({
            icon: "error",
            title: "Download PDF Error",
            text: res.message,
            confirmButtonText: "OK",
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            focusConfirm: true,
          });
        }
      })
      .fail((err) => {
        setId(null);
        Swal.fire({
          icon: "error",
          title: "Download PDF Error",
          text: err.responseJSON.message,
          confirmButtonText: "OK",
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          focusConfirm: true,
        });
      })
      .always(function () {
        setId(null);
      });
  };

  const [bulkdownlaod, setBulkdownlaod] = useState(false);
  const generateBulkPdf = async (regenrate) => {
    setBulkdownlaod(true);
    try {
      const response = await $.post(
        `${config.apiUrl}/guest/teamCardDownloadBulk`,
        JSON.stringify({
          team_id: team?.team_id,
          re_generate: regenrate,
        })
      );
      if (response.status === 200) {
        if (!response.data) {
          Swal.fire({
            icon: "info",
            title: "Processing",
            text: "Cards are being processed, Please check approximately 5-10 minutes later.",
            confirmButtonText: "OK",
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            focusConfirm: true,
          });
        } else {
          const url = response?.data?.card_url;
          window.location.href = url;
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Download PDF Error",
          text: response.data.message,
          confirmButtonText: "OK",
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          focusConfirm: true,
        });
      }
    } catch (error) {
      setBulkdownlaod(false);
      Swal.fire({
        icon: "error",
        title: "Download PDF Error",
        text: error.response?.data?.message || "An error occurred",
        confirmButtonText: "OK",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        focusConfirm: true,
      });
    } finally {
      setBulkdownlaod(false);
    }
  };

  return (
    <>
      <div className="d-flex min-vh-100" style={{ background: "#DAE5FF" }}>
        <Sidebar />
        <div className="content m-3 ms-2 p-4 position-relative pb-8">
          <Header />
          <Breadcrumb path={["Team", "Team Member"]} />

          <main className="bg-white rounded-4" id="userAdd">
            <div className="container-fluid p-4 position-relative">
              <Link
                to="/team"
                className="position-absolute text-decoration-none small"
                style={{ top: "-2rem", right: "0" }}
              >
                Go Back to List
              </Link>
              <form className="row gx-4 gy-2" autoComplete="off">
                <div className="row mb-2">
                  <div className="col-md">
                    <label htmlFor="team_name">Team Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="team_name"
                      value={team?.team_name || ""}
                      disabled
                    />
                  </div>
                  <div className="col-md">
                    <label htmlFor="manager_name">Team Leader Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="manager_name"
                      value={team?.manager_name || ""}
                      disabled
                    />
                  </div>
                  <div className="col-md">
                    <label htmlFor="mobile">Team Leader Mobile</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="mobile"
                      value={team?.mobile || ""}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md">
                    <label htmlFor="team_color">Team Colour</label>
                    <input
                      style={{ height: "41px" }}
                      className="form-control"
                      type="color"
                      id="team_color"
                      value={team?.team_color || ""}
                      disabled
                    />
                  </div>
                  <div className="col-md">
                    <label htmlFor="slots">Team Size</label>
                    <input
                      type="text"
                      className="form-control"
                      id="slots"
                      value={team?.slots || ""}
                      disabled
                    />
                  </div>
                  <div className="col-md">
                    <label htmlFor="event_id">Event Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="event_id"
                      value={team?.event_name || ""}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-12">
                  <table
                    className="table mt-4 rounded"
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <thead
                      style={{
                        fontSize: " 13px",
                        color: "#151940",
                        fontFamily: "Poppins",
                        fontstyle: "normal",
                        fontWeight: "600",
                        lineHeight: "18px",
                      }}
                    >
                      <tr>
                        <th style={{ width: "36px" }}>
                          {teamMember.some((item) => item?.is_active == 0) ? (
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={
                                selectedTeamId.length === filteredItems.length
                              }
                              onChange={handleSelectAll}
                            />
                          ) : (
                            ""
                          )}
                        </th>

                        <th style={{ width: "270px" }}>
                          Name{" "}
                          {reqField?.name == "y" && (
                            <span className="text-danger">*</span>
                          )}
                        </th>
                        {reqField?.mobile == "y" && (
                          <th style={{ width: "200px" }}>
                            Mobile{" "}
                            {reqField?.mobile == "y" && (
                              <span className="text-danger">*</span>
                            )}
                          </th>
                        )}
                        {reqField?.pic == "y" && (
                          <th style={{ width: "100px" }}>
                            Photo{" "}
                            {reqField?.pic == "y" && (
                              <span className="text-danger">*</span>
                            )}
                          </th>
                        )}
                        {reqField?.id_1 == "y" && (
                          <th style={{ width: "100px" }}>
                            Aadhar{" "}
                            {reqField?.id_1 == "y" && (
                              <span className="text-danger">*</span>
                            )}
                          </th>
                        )}
                        {reqField?.id_2 == "y" && (
                          <th style={{ width: "100px" }}>
                            Pan{" "}
                            {reqField?.id_2 == "y" && (
                              <span className="text-danger">*</span>
                            )}
                          </th>
                        )}
                        <th style={{ width: "150px" }}>Status</th>
                        <th style={{ width: "50px" }}>Remark</th>
                        <th style={{ width: "200px" }}>
                          Action{" "}
                          {teamMember.some(
                            (member) => member.is_active == "1"
                          ) ? (
                            <>
                              <Tippy content="Download All">
                                <div className="btn-group me-1">
                                  {bulkdownlaod ? (
                                    <Spinner animation="border" size="sm" />
                                  ) : (
                                    <button
                                      type="button"
                                      disabled={bulkdownlaod}
                                      onClick={() => generateBulkPdf(0)}
                                      style={{
                                        background: "none",
                                        cursor: "pointer",
                                        border: "none",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <FaDownload />
                                    </button>
                                  )}
                                  <>
                                    <button
                                      type="button"
                                      className="text-black dropdown-toggle dropdown-toggle-split border border-0 p-0 bg-transparent"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <span className="visually-hidden">
                                        Toggle Dropdown
                                      </span>
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      style={{ width: "100px !important" }}
                                    >
                                      <li>
                                        <p
                                          className="text-black"
                                          style={{
                                            padding: "0px 2px",
                                            margin: "2px 0px",
                                            color: "black",
                                          }}
                                          role="button"
                                          onClick={() => generateBulkPdf(1)}
                                        >
                                          Regenerate{" "}
                                          {bulkdownlaod ? (
                                            <Spinner
                                              animation="border"
                                              size="sm"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      </li>
                                    </ul>
                                  </>
                                </div>
                              </Tippy>
                            </>
                          ) : (
                            ""
                          )}
                          {/* <Tippy content="Bulk Action">
                            {teamMember.some((item) => item?.is_active == 0) ? (
                              selectedTeamId?.length > 0 ? (
                                <button
                                  type="button"
                                  onClick={handleShowModal}
                                  style={{
                                    background: "none",
                                    cursor: "pointer",
                                    border: "none",
                                    fontSize: "20px",
                                  }}
                                >
                                  <GrActions />
                                </button>
                              ) : null
                            ) : (
                              ""
                            )}
                          </Tippy>
                          <Tippy content="Download">
                            {teamMember?.length > 0 ? (
                              <DownloadExcel
                                link={`${config.apiUrl}/transTeam/getTeamConf`}
                                data={{ team_id: team?.team_id }}
                                filename="Team Member"
                              />
                            ) : null}
                          </Tippy> */}
                          {teamMember.some((item) => item?.is_active == 0) &&
                          selectedTeamId?.length > 0 ? (
                            <Tippy content="Bulk Action">
                              <button
                                type="button"
                                onClick={handleShowModal}
                                style={{
                                  background: "none",
                                  cursor: "pointer",
                                  border: "none",
                                  fontSize: "20px",
                                }}
                              >
                                <GrActions />
                              </button>
                            </Tippy>
                          ) : null}
                          {teamMember?.length > 0 ? (
                            <DownloadExcel
                              link={`${config.apiUrl}/transTeam/getTeamConf`}
                              data={{ team_id: team?.team_id }}
                              filename="Team Member"
                            />
                          ) : null}
                          <Modal
                            show={showModal}
                            onHide={handleCloseModal}
                            id="userAdd"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Bulk Actions</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div
                                className="d-flex align-items-center gap-4"
                                id="userAdd"
                              >
                                <div className="d-flex gap-1 align-items-center">
                                  <input
                                    type="checkbox"
                                    htmlFor="approve"
                                    onChange={() => {
                                      setShowApproveAction(!showApproveAction);
                                      setSelectArea("");
                                      setActionReason("");
                                      if (!showApproveAction) {
                                        setShowRejectAction(false);
                                      }
                                    }}
                                    checked={showApproveAction}
                                  />
                                  <label
                                    onClick={() => {
                                      setShowApproveAction(!showApproveAction);
                                      setSelectArea("");
                                      setActionReason("");
                                      if (!showApproveAction) {
                                        setShowRejectAction(false);
                                      }
                                    }}
                                    id="approve"
                                    role="button"
                                  >
                                    Approve
                                  </label>
                                </div>
                                <div className="d-flex gap-1 align-items-center">
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      setShowRejectAction(!showRejectAction);
                                      if (!showRejectAction) {
                                        setShowApproveAction(false);
                                      }
                                    }}
                                    name="actions"
                                    checked={showRejectAction}
                                  />
                                  <label
                                    onClick={() => {
                                      setShowRejectAction(!showRejectAction);
                                      if (!showRejectAction) {
                                        setShowApproveAction(false);
                                      }
                                    }}
                                    role="button"
                                    name="actions"
                                  >
                                    Reject
                                  </label>
                                </div>
                              </div>

                              {showApproveAction && (
                                <>
                                  <br />
                                  {eventArea.length > 0 ? (
                                    <Select
                                      isMulti
                                      options={eventArea?.map((v) => ({
                                        label: v?.event_area,
                                        value: v?.event_area_id,
                                      }))}
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          minHeight: "0px",
                                          marginBottom: "8px",
                                          // height: "35px",
                                        }),
                                        valueContainer: (baseStyles) => ({
                                          ...baseStyles,
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                          // height:"35px"
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                          marginTop: "0px",
                                          marginBottom: "0px",
                                        }),
                                        dropdownIndicator: (baseStyles) => ({
                                          ...baseStyles,
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                        }),
                                        option: (baseStyles) => ({
                                          ...baseStyles,
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                        }),
                                      }}
                                      // classNamePrefix="react-select"
                                      value={selectArea}
                                      tabSelectsValue
                                      onChange={(selectedOption) => {
                                        setSelectArea(selectedOption);
                                      }}
                                    />
                                  ) : null}
                                  <div className="d-flex justify-content-end mt-4">
                                    <button
                                      className="btn btn-primary rounded-1 py-0 px-1 m-0"
                                      type="button"
                                      onClick={handleApproveAction}
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting ? (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                      ) : null}

                                      {eventArea.length > 0
                                        ? "Submit"
                                        : "Approve"}
                                    </button>
                                  </div>
                                </>
                              )}
                              {showRejectAction && (
                                <>
                                  <br />
                                  <textarea
                                    className="w-100"
                                    placeholder="Reason"
                                    value={actionReason}
                                    onChange={(e) => {
                                      setActionReason(e.target.value);
                                    }}
                                  ></textarea>
                                  <div className="d-flex justify-content-end mt-4">
                                    <button
                                      className="btn btn-primary rounded-1 py-0 px-1 m-0"
                                      type="button"
                                      onClick={handleRejectAction}
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting ? (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                      ) : null}
                                      Submit
                                    </button>
                                  </div>
                                </>
                              )}
                            </Modal.Body>
                            <Modal.Footer></Modal.Footer>
                          </Modal>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamMember &&
                        teamMember.map((v, i) => (
                          <tr key={i} data-id={i}>
                            <td>
                              {v?.is_active == 0 && (
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={selectedTeamId.includes(v)}
                                  onChange={() => handleCheckboxChange(v)}
                                />
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                value={v?.name || ""}
                                disabled
                              />
                            </td>
                            {reqField?.mobile == "y" && (
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={v?.mobile || ""}
                                  disabled
                                />
                              </td>
                            )}
                            {reqField?.pic == "y" && (
                              <td>
                                <img
                                  style={{ width: "32px", height: "32px" }}
                                  src={v?.pic || noImage}
                                  onClick={() => {
                                    v?.pic &&
                                      handleImageClick(v?.pic || noImage);
                                  }}
                                  role="button"
                                  alt=""
                                />
                              </td>
                            )}
                            {reqField?.id_1 == "y" && (
                              <td>
                                <img
                                  style={{ width: "32px", height: "32px" }}
                                  src={v?.id_1 || noImage}
                                  onClick={() => {
                                    v?.id_1 && handleImageClick(v?.id_1);
                                  }}
                                  role="button"
                                  alt=""
                                />
                              </td>
                            )}
                            {reqField?.id_2 == "y" && (
                              <td>
                                <img
                                  style={{ width: "32px", height: "32px" }}
                                  src={(v?.id_2 && v?.id_2) || noImage}
                                  onClick={() => {
                                    v?.id_2 && handleImageClick(v?.id_2);
                                  }}
                                  role="button"
                                  alt=""
                                />
                              </td>
                            )}
                            <td>
                              <input
                                style={{ width: "110px" }}
                                id="approvalStatus"
                                type="text"
                                value={
                                  v?.is_active == 1
                                    ? "Approved"
                                    : v?.is_active == 2
                                    ? "Rejected"
                                    : "Pending"
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <Tippy
                                className="bg-white p-2 text-black border border-2 form-control"
                                content={
                                  v?.is_active == 1
                                    ? v?.area_name || "All"
                                    : v?.is_active == 2
                                    ? v?.rej_reason
                                    : "No reason provided"
                                }
                                trigger="mouseenter"
                              >
                                <span
                                  style={{
                                    fontSize: "1.2rem",
                                    cursor: "pointer",
                                  }}
                                  className="fw-bold mx-2"
                                >
                                  i
                                </span>
                              </Tippy>
                            </td>
                            {v?.is_active == 1 ? (
                              <td>
                                {id == v?.team_dtl_id ? (
                                  <span className="spinner-border spinner-border-sm me-2"></span>
                                ) : (
                                  <Tippy content="Download">
                                    <img
                                      onClick={() => generatePdf(v)}
                                      src={downloadIcon}
                                      style={{
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tippy>
                                )}
                                <Tippy content="Edit">
                                  <span>
                                    <MdModeEditOutline
                                      onClick={() => {
                                        showHandleApprove(i);
                                        const areaArray = v.area_id
                                          .split(",")
                                          .map(Number);
                                        const filteredAreas = eventArea.filter(
                                          (area) =>
                                            areaArray.includes(
                                              area.event_area_id
                                            )
                                        );
                                        setSelectArea(
                                          filteredAreas.map((area) => ({
                                            label: area.event_area,
                                            value: area.event_area_id,
                                          }))
                                        );
                                      }}
                                      role="button"
                                      className="ms-2"
                                    />
                                  </span>
                                </Tippy>

                                {showApprove && showApprove[i] && (
                                  <>
                                    {eventArea.length > 0 ? (
                                      <Select
                                        isMulti
                                        options={eventArea?.map((v) => ({
                                          label: v?.event_area,
                                          value: v?.event_area_id,
                                        }))}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            minHeight: "0px",
                                            marginBottom: "8px",
                                          }),
                                          valueContainer: (
                                            baseStyles,
                                            state
                                          ) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }),
                                          input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                          }),
                                          dropdownIndicator: (
                                            baseStyles,
                                            state
                                          ) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }),
                                          option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }),
                                        }}
                                        name={`event_area_${i}`}
                                        id={`event_area_${i}`}
                                        classNamePrefix="react-select"
                                        value={selectArea}
                                        Approve
                                        tabSelectsValue
                                        onChange={(selectedOption) => {
                                          setSelectArea(() => selectedOption);
                                        }}
                                      />
                                    ) : null}

                                    <button
                                      className="btn btn-primary rounded-1 py-0 px-1 m-0"
                                      type="button"
                                      onClick={() =>
                                        handleApproveEdit(i, v?.team_dtl_id)
                                      }
                                      disabled={
                                        approve == v?.team_dtl_id ? true : false
                                      }
                                    >
                                      {approve == v?.team_dtl_id ? (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                      ) : (
                                        ""
                                      )}
                                      {eventArea.length > 0
                                        ? "Submit"
                                        : "Approve"}
                                    </button>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  width: "20%",
                                }}
                              >
                                <Tippy content="Approve">
                                  <img
                                    src={approveIcon}
                                    className="img-fluid m-1"
                                    onClick={() => {
                                      showHandleApprove(i);
                                    }}
                                    role="button"
                                    alt=""
                                  />
                                </Tippy>
                                <Tippy content="Reject">
                                  <img
                                    src={deleteIcon}
                                    className="img-fluid m-1"
                                    onClick={() => {
                                      showHandleReject(i);
                                    }}
                                    role="button"
                                    alt=""
                                  />
                                </Tippy>
                                {showApprove && showApprove[i] && (
                                  <>
                                    {eventArea.length > 0 ? (
                                      <Select
                                        isMulti
                                        options={eventArea?.map((v) => ({
                                          label: v?.event_area,
                                          value: v?.event_area_id,
                                        }))}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            minHeight: "0px",
                                            marginBottom: "8px",
                                          }),
                                          valueContainer: (
                                            baseStyles,
                                            state
                                          ) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }),
                                          input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                          }),
                                          dropdownIndicator: (
                                            baseStyles,
                                            state
                                          ) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }),
                                          option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }),
                                        }}
                                        name={`event_area_${i}`}
                                        id={`event_area_${i}`}
                                        classNamePrefix="react-select"
                                        value={selectArea}
                                        tabSelectsValue
                                        onChange={(selectedOption) => {
                                          setSelectArea(() => selectedOption);
                                        }}
                                      />
                                    ) : null}

                                    <button
                                      className="btn btn-primary rounded-1 py-0 px-1 m-0"
                                      type="button"
                                      onClick={() =>
                                        handleApprove(i, v?.team_dtl_id)
                                      }
                                      disabled={
                                        approve == v?.team_dtl_id ? true : false
                                      }
                                    >
                                      {approve == v?.team_dtl_id ? (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                      ) : (
                                        ""
                                      )}
                                      {eventArea.length > 0
                                        ? "Submit"
                                        : "Approve"}
                                    </button>
                                  </>
                                )}
                                {showReject && showReject[i] && (
                                  <>
                                    <br />
                                    <textarea
                                      id={`reason_${i}`}
                                      className="w-100"
                                      defaultValue={v?.rej_reason}
                                      placeholder="Reason"
                                    ></textarea>
                                    <button
                                      className="btn btn-primary rounded-1 py-0 px-1 m-0"
                                      type="button"
                                      onClick={() =>
                                        handleReject(i, v?.team_dtl_id)
                                      }
                                    >
                                      {reject == v?.team_dtl_id ? (
                                        <span className="spinner-border spinner-border-sm me-2"></span>
                                      ) : (
                                        ""
                                      )}
                                      Submit
                                    </button>
                                  </>
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>

      <Toaster />

      <Modal
        closeButton
        show={isModalOpen}
        onHide={handleModalClose}
        keyboard={false}
        backdrop="static"
      >
        <img src={modalImage} alt="" />
        <button onClick={handleModalClose}>Close</button>
      </Modal>
    </>
  );
}

export default IteamList;
